
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import { mediaWidths } from '@/plugins/enums'

export default {
  props: {
    src: {
      type: [Object, String],
      default: null,
    },
    ratio: {
      type: [String, Number],
      default: 1,
    },
    width: {
      type: [String, Number],
      default: 0,
    },
    height: {
      type: [String, Number],
      default: 0,
    },
    alt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      widths: mediaWidths,
    }
  },
  computed: {
    calculatedRatioPadding() {
      let ratio = this.ratio
      if (typeof ratio === 'string') {
        ratio = ratio
          .split('/')
          .map((x) => parseFloat(x))
          .reduce((p, c) => c / p, 1)
      }

      return 100 * ratio
    },
    calculatedRatioStyle() {
      return `padding-bottom: ${this.calculatedRatioPadding}%`
    },
    calculatedSizes() {
      const { width, height } = this
      return {
        width: parseInt(width) > 0 ? this.withUnit(width) : 'auto',
        height: parseInt(height) > 0 ? this.withUnit(height) : 'auto',
      }
    },
    defaultImage() {
      if (!this.src) {
        return ''
      }

      const width = this.width ? parseInt(this.width) : 500

      if (width < 150) {
        return this.src['100w'] && this.src['100w'].src
      } else if (width < 250) {
        return this.src['200w'] && this.src['200w'].src
      } else if (width < 350) {
        return this.src['300w'] && this.src['300w'].src
      } else if (width < 450) {
        return this.src['400w'] && this.src['400w'].src
      }

      return this.src['500w'] && this.src['500w'].src
    },
    defaultSrcSet() {
      return this.srcSet('src')
    },
    webpSrcSet() {
      return this.srcSet('webp')
    },
  },
  methods: {
    withUnit(value) {
      const stringValue = value.toString()

      return stringValue.includes('%')
        ? `${parseInt(stringValue)}%`
        : stringValue.includes('rem')
        ? `${parseInt(stringValue)}rem`
        : stringValue.includes('vh')
        ? `${parseInt(stringValue)}vh`
        : stringValue.includes('vw')
        ? `${parseInt(stringValue)}vw`
        : `${parseInt(stringValue)}px`
    },
    srcSet(format = 'src') {
      if (!this.src) {
        return ''
      }

      const srcSet = []

      if (this.src.width) {
        srcSet.push({
          width: this.src.width,
          url: this.src.original[format],
        })
      }

      this.widths.forEach((width) => {
        if (this.src[`${width}w`]) {
          srcSet.push({
            width,
            url: this.src[`${width}w`][format],
          })
        }
      })

      return srcSet
        .reverse()
        .map((data) => {
          return `${data.url} ${data.width}w`
        })
        .join(',\n')
    },
  },
}
